import { CopyOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Popconfirm, Select, Spin, message } from "antd";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { addCow, allCows, allHissa, cowReports, cowSearch, deleteAnimal } from "../../../modules/actions";
import AddCow from "./AddCow";
import "./cow.css";
const CopyCow = lazy(() => import("./CopyCow"));

const { Option } = Select;

const Cow = (props) => {
  let [search, setSearch] = useState("");
  let [day, setDay] = useState(4);
  let [data, setData] = useState([]);
  let [filter, setFilter] = useState("Pending");
  const [showCopyCowModal, setShowCopyCowModal] = useState(false);
  const [, setSelectedCowId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hissaData, setHissaData] = useState([]);
  const [area, setArea] = useState("All");
  function confirm(e, id) {
    deleteCow(id);
  }

  function cancel(e) {
    message.error("Click on No");
  }

  useEffect(() => {
    props.allCows(filter, day, area);
  }, [filter, day, area]);

  useEffect(() => {
    props.cowSearch(search, "Cow");
  }, [search]);
  useEffect(() => {
    if (search) {
      setData(props.cowSearchData);
    } else {
      setData(props.allCowData);
    }
  }, [search, props]);
  useEffect(() => {
    props.cowReports(day);
  }, [day]);
  function hissaRemaining(id) {
    return (
      props.cowReportData &&
      props.cowReportData.map((a, i) => {
        if (a._id === id) {
          return 7 - a.hissaInfo.length;
        }
      })
    );
  }
  function deleteCow(id) {
    let obj = {
      id: id,
    };
    props.deleteAnimal(obj);
  }
  const handleCopyCowClick = (cowId) => {
    setIsLoading(true);
    const cowIdString = String(cowId);
  
    // fetch(`${process.env.REACT_APP_PUBLIC_SERVER_URL}/getAllCowsHissa?cowId=${cowIdString}`)
    fetch(`http://65.0.3.171:3008/api/users/getAllCowsHissa?cowId=${cowIdString}`)
    
      .then((response) => {
        if (!response.ok) {
          throw new Error("Copy cow failed");
        }
        return response.json();
      })
      .then((data) => {
        const hissaArray = data.hissa;
        if (hissaArray && hissaArray.length > 0) {
          setHissaData(hissaArray);
          setSelectedCowId(cowId);
          setShowCopyCowModal(true);
        } else {
          message.error("Copy cow failed");
        }
      })
      .catch((error) => {
        message.error("Copy cow failed");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  

  useEffect(() => {}, [hissaData]);

  const handleCloseModal = () => {
    setSelectedCowId(null);
    setShowCopyCowModal(false);
  };
  return (
    <div className="CowPage">
      <div className="innerCow">
        <div className="toolBar">
          <h3>Cow Management</h3>
          <div className="toolBtn">
            <div class="toggleBtn">
              <div class="tabs">
                <input
                  type="radio"
                  id="radio-1"
                  name="tabs"
                  checked={filter === "Completed" ? true : false}
                />
                <label
                  class="tab"
                  for="radio-1"
                  onClick={() => setFilter("Completed")}
                >
                  Cow
                </label>
                <input
                  type="radio"
                  id="radio-2"
                  name="tabs"
                  checked={filter === "Pending" ? true : false}
                />
                <label
                  class="tab"
                  for="radio-2"
                  onClick={() => setFilter("Pending")}
                >
                  Hissa
                </label>
                <span class="glider"></span>
              </div>
            </div>
            <AddCow />
          </div>
        </div>
        <div className="filterDiv">
          <div className="search">
            <SearchOutlined />
            <input
              placeholder="Enter Cow No"
              onChange={(ev) => setSearch(ev.target.value)}
            />
          </div>
          <div>
            <Select placeholder="Day" onChange={(ev) => setDay(ev)}>
              <Option value={4}>All Day</Option>
              <Option value={1}>Day 1</Option>
              <Option value={2}>Day 2</Option>
              <Option value={3}>Day 3</Option>
            </Select>
          </div>
          <div>
            <Select placeholder="Area" onChange={(ev) => setArea(ev)}>
            <Option value="All">All</Option>
                <Option value="Munir Fountain">Munir Fountain</Option>
                <Option value="Johar 19">Johar 19</Option>
                <Option value="Karachi">Karachi</Option>
            </Select>
          </div>
        </div>
        <div className="boxDiv">
          <div className="innerboxDiv">
            {data &&
              data.map((a, i) => {
                return (
                  <div className="hissaBox">
                    <button
                      className="copyCowBtn"
                      onClick={() => handleCopyCowClick(a._id)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spin indicator={<LoadingOutlined />} />
                      ) : (
                        <>
                          <CopyOutlined />
                          <span className="copyCowText">Copy Cow</span>
                        </>
                      )}
                    </button>
                    {showCopyCowModal && (
                      <div className="modalOverlay">
                        <div className="modalContent">
                          <Suspense fallback={<div>Loading...</div>}>
                            <CopyCow onClose={handleCloseModal} hissaData={hissaData} />
                            </Suspense>
                        </div>
                      </div>
                    )}
                    <div
                      className="cow"
                      onClick={() =>
                        props.history.push(
                          `/hissa-management/cow/cowDetail/${a._id}/${a.sequencenNumber}/${a.day}`
                        )
                      }
                    >
                      <div className="innerCow">
                        <h3>Cow No: {a.sequencenNumber}</h3>
                        <h5>
                          Cow Status:{" "}
                          <span
                            className={
                              a.status === "Completed"
                                ? "closed"
                                : a.status === "Pending"
                                ? "open"
                                : ""
                            }
                          >
                            {a.status === "Completed"
                              ? "Closed"
                              : a.status === "Pending"
                              ? "Open"
                              : null}
                          </span>
                        </h5>
                      </div>
                      {a.status === "Completed" ? null : (
                        <p>Remaining Hissa: {hissaRemaining(a._id)}</p>
                      )}
                      <h6>Year: {a.year}</h6>

                      <h6>Area: {a.area}</h6>
                    </div>
                    <div>
                      <div className="hissaBtn">
                        <Popconfirm
                          title="Are you sure to delete this hissa"
                          onConfirm={(e) => {
                            confirm(e, a._id);
                          }}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button className="deleteBtn">Delete</button>
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ store }) => ({
  allCowData: store.allCowData,
  cowSearchData: store.cowSearchData,
  allHissaData: store.allHissaData,
  cowReportData: store.cowReportData,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addCow,
      cowSearch,
      allCows,
      allHissa,
      cowReports,
      deleteAnimal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cow));
