import { Pagination, Select } from "antd";
import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cowReports } from "../../../modules/actions";
import "./cowReport.css";

const { Option } = Select;

const CowReport = (props) => {
  let [data, setData] = useState("");
  let [day, setDay] = useState(1);
  let [page, setPage] = useState(1);
  let [pageLength, setPageLength] = useState();
  let [, setCowNo] = useState();
  const [area, setArea] = useState("All");

  useEffect(() => {
    props.cowReports(day, area);
  }, [day, area]);

  useEffect(() => {
    if (props.cowReportData) {
      setPageLength(props.cowReportData.length);
      setData(
        props.cowReportData.sort((a, b) => {
          return a.sequencenNumber - b.sequencenNumber;
        })
      );
    }
  }, [props, day, page, area]);
  
  function onDayChange(value) {
    setDay(value);
  }

  function onAreaChange(value) {
    setArea(value);
  }

  const paginate = (pageNo) => {
    setPage(pageNo);
  };
  useEffect(() => {
    setCowNo(data && data[0] && data[0].sequencenNumber);
  }, [data]);

  var month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className="reportTable">
      <Select
        placeholder="Select Cow Number"
        onChange={onDayChange}
        defaultValue={day}
        style={{ marginBottom: "16px", width: "40%" }}
      >
        <Option value={4}>All Day</Option>
        <Option value={1}>Day 1</Option>
        <Option value={2}>Day 2</Option>
        <Option value={3}>Day 3</Option>
      </Select>

      <Select
        placeholder="Area"
        onChange={onAreaChange}
        defaultValue={area}
        style={{ marginBottom: "16px", marginLeft: "20%", width: "40%" }}
      >
        <Option value="All">All</Option>
        <Option value="Munir Fountain">Munir Fountain</Option>
        <Option value="Johar 19">Johar 19</Option>
        <Option value="Karachi">Karachi</Option>
      </Select>

      <table id="table-to-xls" style={{ width: "7.66in" }}>
        {data &&
          data.map((a, i) => {
            return a.hissaInfo.length ? (
              <>
                

                <thead style={{ marginTop: "100px" }}>
                  <tr className="mainHeading">
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        fontSize: "24px",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                      colSpan={10}
                    >
                      Qurbani Booking (
                      {month[new Date(a.hissaInfo[0].bookingDate).getMonth()]}{" "}
                      {a.hissaInfo[0].year}) COW # {a.sequencenNumber} -{" "}
                      {day === 1
                        ? `First Day`
                        : day === 2
                        ? `Second Day`
                        : day === 3
                        ? `Third Day`
                        : day === 4
                        ? `All`
                        : `First Day`}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Rcpt
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Addditional
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Addditional Information
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Expected Delivery
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      P/N
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        width: "40%",
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Address
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Paid /<br /> Unpaid
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Tel
                    </th>
                    <th
                      style={{
                        backgroundColor: "#BE7976",
                        height: "50px",
                        textAlign: "center",
                        border: "1px solid black",
                        verticalAlign: "middle",
                      }}
                    >
                      Mobile
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {a.hissaInfo
                    ? a.hissaInfo.map((b, j) => {
                        return (
                          <tr>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {j + 1}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.specialInstruction &&
                                Object.keys(b.specialInstruction)
                                  .filter(
                                    (c, k) =>
                                      b.specialInstruction[c] !== "false" &&
                                      c !== "zabihha"
                                  )
                                  .map((d, l, arr) => {
                                    return d !== "zabihha" ? (
                                      l < arr.length - 1 ? (
                                        l % 2 === 0 ? (
                                          <React.Fragment key={l}>
                                            {`${d} - `}
                                          </React.Fragment>
                                        ) : (
                                          `${d} - `
                                        )
                                      ) : l % 2 === 0 ? (
                                        <React.Fragment key={l}>
                                          {`${d}`}
                                        </React.Fragment>
                                      ) : (
                                        d
                                      )
                                    ) : (
                                      ""
                                    );
                                  })}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.additionalInformation}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.expectedDelivery}
                            </td>

                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.sequencenNumber}x{j + 1}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.hissadarName}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.address &&
                                ` 
                                        ${
                                          b.address.houseNumber
                                            ? `House No ${b.address.houseNumber}`
                                            : ""
                                        } 
                                        ${
                                          b.address.street
                                            ? `${b.address.street}`
                                            : ""
                                        } 
                                        ${
                                          b.address.nearBy
                                            ? `Near ${b.address.nearBy}`
                                            : ""
                                        } 
                                        ${
                                          b.address.area
                                            ? `Area ${b.address.area}`
                                            : ""
                                        } 
                                        `}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.isPaid}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.contact2}
                            </td>
                            <td
                              style={{
                                height: "50px",
                                textAlign: "center",
                                border: "1px solid black",
                                verticalAlign: "middle",
                              }}
                            >
                              {b.contact1}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
                <tfooter className="noneRow">
                  {/* <tr>
                                    <td style={{border:"none"}} colspan="6"></td>
                                </tr> */}
                  {/* <tr>
                                    <td style={{border:"none"}} colspan="6"></td>
                                </tr> */}
                  {/* <tr>
                                    <td style={{border:"none"}} colspan="8"></td>
                                </tr>
                                <tr>
                                    <td style={{border:"none"}} colspan="8"></td>
                                </tr>
                                <tr>
                                    <td style={{border:"none"}} colspan="8"></td>
                                </tr>
                                <tr>
                                    <td style={{border:"none"}} colspan="8"></td>
                                </tr>
                                <tr>
                                    <td style={{border:"none"}} colspan="8"></td>
                                </tr>
                                <tr>
                                    <td style={{border:"none"}} colspan="8"></td>
                                </tr> */}
                </tfooter>
              </>
            ) : null;
          })}
      </table>
      <div className="pagePrint">
        <Pagination
          defaultCurrent={1}
          total={pageLength}
          pageSize={1}
          onChange={(e) => paginate(e)}
        />
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Download as XLS"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ store }) => ({
  cowReportData: store.cowReportData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cowReports,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CowReport);
