import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Logo from '../../Images/Logo 2.png'
// import './home.scss'
import './home.css'
import { cowReports, dashboard } from '../../modules/actions'

function Home(props) {
  const [dashboardState, setDashboardState] = useState("")
  useEffect(() => {
    props.dashboard()
    // props.cowReports()
  }, [])
  useEffect(() => {
    setDashboardState(props.dashboardState)
  }, [props.dashboardState])
  // console.log(props.cowReportData)
  return (
    <div className="dashboard">
      <div className="innerDashboard">
        <div className="stats">
          <h3>{dashboardState.totalCows}</h3>
          <h5>Total Cows</h5>
        </div>
        <div className="stats">
          <h3>{dashboardState.totalGoats}</h3>
          <h5>Total Goats</h5>
        </div>
        {/* <div className="stats">
          <h3>6</h3>
          <h5>Total Booked Cows</h5>
        </div> */}
        <div className="stats">
          <h3>{dashboardState.totalVacantCows}</h3>
          <h5>Open Cows</h5>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ store }) => ({
  dashboardState: store.dashboard,
  cowReportData: store.cowReportData
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dashboard,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
