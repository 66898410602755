import { Checkbox } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
// import './login.scss'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { Login } from '../../modules/actions';
import './login.css';


const LoginForm = (props) => {
    const onFinish = (values) => {
        //console.log('Received values of form: ', values);
        let obj = {
            "username": values.username,
            "password": values.password
        }
        props.Login(obj)
    };

useEffect(()=>{
    if(localStorage.getItem('login')){
        window.location.href = '/dashboard'
    }
},[])

    return (
        <div className="loginForm">
            <div className="sideBanner">
                <div className="text">
                    <h1>
                        Ijtimai Qurabani Management
                    </h1>
                </div>
            </div>
            <div className="formDiv">
                <h2>Login</h2>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="">
                            Forgot password
        </a>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
        </Button>
                        {/* Or <a href="">register now!</a> */}
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
const mapStateToProps = ({ store }) => ({
    dashboardState: store.dashboard,

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            Login
        },
        dispatch
    )
export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
        (withRouter(LoginForm))


