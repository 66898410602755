import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
// import './riderReport.scss'
import { Select } from 'antd';
import { bindActionCreators } from 'redux';
import { allGoats, cowReports } from '../../../modules/actions';
import './riderReport.css';
const { Option } = Select;

const RiderReport = (props) => {

    const ref = useRef();
    let [filter, setFilter] = useState('cow')
    const [day, setDay] = useState(4);
    const [area, setArea] = useState("All");

    useEffect(() => {
        props.cowReports(day, area)
    }, [day, area])

    useEffect(() => {
        if (filter === 'cow') {
            props.cowReports(4)
        } else if (filter === 'goat') {
            props.allGoats(4)
        }
    }, [filter])

    useEffect(() => {
        if (filter === 'cow') {
            if (props.cowReportData) {
            }
        } else if (filter === 'goat') {
            if (props.allGoatData) {
            }
        }
    }, [props, filter])

    function onDayChange(value) {
        setDay(value);
      }
    
      function onAreaChange(value) {
        setArea(value);
      }

    const pdf = () => {
        let content = document.getElementById('reportDiv').innerHTML
        var win = window.open();

        var style = `<style>
        body{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        h1,h2,h3,h4,h5,h6,p{
            margin: 0px
        }
        .reportDiv {
            padding: 20px calc(100% - 1000px);
            float:none
       }
       .reportDiv .report {
           border: 1px solid black;
           margin: 10px 10px 0px;
           width: 340px;
           height: 160px;
           display: flex;
           align-items: center;
           justify-content: center;
        }
        @media print {
        .reportDiv .pageBreak{
            break-after: always;
        }
    }
    
        .reportDiv .report .innerReport {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            padding: 10px;

        }
        .reportDiv .report .innerReport h2 {
            font-size: 30px;
       }
        .reportDiv .report .innerReport h5 {
            font-size: 20px;
       }
        .reportDiv .report .innerReport h6 {
            font-size: 14px;
       }
        .reportDiv .report .innerReport p {
            font-size: 15px;
       }
        
       </style>`
        win.document.write('<html><head>');
        win.document.write('<title>Profile</title>');
        win.document.write(style);
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write(content);
        win.document.write(`</body></html>`);
        win.document.close();
        setTimeout(() => {
            win.print();
        }, 700)
    }

    return (
        <>

            <div className="riderReport" ref={ref}>
            <Select placeholder="Select Day" onChange={onDayChange} defaultValue={day} style={{ marginBottom: '16px', width: "40%" }}>
              <Option value={4}>All Day</Option>
              <Option value={1}>Day 1</Option>
              <Option value={2}>Day 2</Option>
              <Option value={3}>Day 3</Option>
            </Select>

            <Select placeholder="Area" onChange={onAreaChange} defaultValue={area} style={{ marginBottom: '16px', marginLeft:'20%', width: "40%" }}>
                <Option value="All">All</Option>
                <Option value="Munir Fountain">Munir Fountain</Option>
                <Option value="Johar 19">Johar 19</Option>
                <Option value="Karachi">Karachi</Option>
            </Select>
                <div className="buttonPdf">
                    <div className="toggleBtn">
                        <div className="tabs">
                            <input type="radio" id="radio-1" name="tabs" checked={filter === 'cow' ? true : false} />
                            <label className="tab" for="radio-1" onClick={() => setFilter('cow')}>Cow</label>
                            <input type="radio" id="radio-2" name="tabs" checked={filter === 'goat' ? true : false} />
                            <label className="tab" for="radio-2" onClick={() => setFilter('goat')}>Goat</label>
                            <span className="glider"></span>
                        </div>
                    </div>
                    <button className="pdfBtn" onClick={pdf}>Save As PDF</button>
                </div>
                <div className="inneRiderReport" id="reportDiv">
                    <div className="reportDiv" >
                        {filter === 'cow' ?
                            props.cowReportData && props.cowReportData.sort((a, b) => { return (a.sequencenNumber - b.sequencenNumber) }).map((a, i) => {
                             
                                return (
                                    <>
                                   { a.hissaInfo.length ? a.hissaInfo.map((b, j) => {
                                        return (
                                            <div style={(j + 1) % 2 !== 0 ? { float: 'left' } : null} className="report">
                                                <div className="innerReport">
                                                    <h2>Cow - {a.sequencenNumber}/{j + 1}</h2>
                                                    <h5>{b.hissadarName}</h5>
                                                    <h6>{b.address && `${b.address.houseNumber ? b.address.houseNumber : ''} ${b.address.street ? b.address.street : ''} ${b.address.area ? b.address.area : ''} ${b.address.nearBy ? b.address.nearBy : ''}  `}</h6>
                                                    <p>{b.contact1 ? b.contact1 : b.contact2 ? b.contact2 : ''}</p>
                                                </div>
                                            </div>

                                        )

                                    }) : null}
                                
                                     <p style={{ pageBreakAfter: 'always' }}></p> 
                                    </>

                                )
                            }) : filter === 'goat' ?
                                props.allGoatData && props.allGoatData.sort((a, b) => { return (a.sequencenNumber - b.sequencenNumber) }).map((a, i) => {
                                    return ((i + 1) % 8) !== 0 ? (
                                        <div style={(i + 1) % 2 !== 0 ? { float: 'left' } : null} className="report">
                                            <div className="innerReport">
                                                <h2>Goat - {a.sequencenNumber}</h2>
                                                <h5>{a.hissadarName}</h5>
                                                <h6>{a.address && `${a.address.houseNumber ? a.address.houseNumber : ''} ${a.address.street ? a.address.street : ''} ${a.address.area ? a.address.area : ''} ${a.address.nearBy ? a.address.nearBy : ''}  `}</h6>
                                                <p>{a.contact1 ? a.contact1 : a.contact2 ? a.contact2 : ''  }</p>
                                            </div>
                                        </div>

                                    ) :
                                        <>
                                            <div style={(i + 1) % 2 !== 0 ? { float: 'left' } : null} className="report">
                                                <div className="innerReport">
                                                    <h2>Goat - {a.sequencenNumber}</h2>
                                                    <h5>{a.hissadarName}</h5>
                                                    <h6>{a.address && `${a.address.houseNumber ? a.address.houseNumber : ''} ${a.address.street ? a.address.street : ''} ${a.address.area ? a.address.area : ''} ${a.address.nearBy ? a.address.nearBy : ''}  `}</h6>
                                                    <p>{a.contact1 ? a.contact1 : a.contact2 ? a.contact2 : ''  }</p>
                                                </div>
                                            </div>
                                            <p style={{ pageBreakAfter: 'always' }}></p>
                                        </>


                                }) : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ store }) => ({
    cowReportData: store.cowReportData,
    allGoatData: store.allGoatData
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            allGoats,
            cowReports
        },
        dispatch
    )
export default connect(mapStateToProps, mapDispatchToProps)(RiderReport)
