import React, { useState } from "react";
import { connect } from "react-redux";
import { Select, Popconfirm, message } from "antd";
import AddHissa from "../cow/AddHissa";
import { SearchOutlined } from "@ant-design/icons";
import cowSlipHeader from "./../../../Images/cowSlipHeader.jpg";
import { cowSearch, allHissa, deleteHissa } from "../../../modules/actions";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

const { Option } = Select;
const HissaCow = (props) => {
  const [id, setId] = useState();
  const [cowNumber, setCowNumber] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [day, setDay] = useState(4);
  const [copyObj, setCopyObj] = useState("");
  const [copied, settCopied] = useState(false);
  const [editObj, setEditObj] = useState();
  const cowSearchData = props.cowSearchData || [];
  const handleSearch = (value) => {
    setSearch(value);
    props.cowSearch(value, "hissa");
  };

  const confirm = (e) => {
    // console.log(e);
  };

  const cancel = (e) => {
    // console.log(e);
    message.error("Click on No");
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditObj(false);
  };

  function deleteHissaFn(idHissa) {
    let obj = {
      hissaId: idHissa,
    };
    props.deleteHissa(obj, id);
  }

  return (
    <div className="CowPage">
      <div className="innerCow">
        <div className="toolBar">
          <h3>Hissa Management</h3>
          <div className="toolBtn">
            <AddHissa
              clipHeader={cowSlipHeader}
              hissaDetail={props.allHissaData}
              cowNumber={cowNumber}
              cowId={id}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              showModal={showModal}
              handleOk={handleOk}
              handleCancel={handleCancel}
              editObj={editObj}
              copyObj={copyObj}
              setCopyObj={setCopyObj}
              copied={copied}
              settCopied={settCopied}
            />
          </div>
        </div>
        <div className="filterDiv">
          <div className="search">
            <SearchOutlined />
            <input
              placeholder="Enter Hissedar Name"
              onChange={(ev) => handleSearch(ev.target.value)}
            />
          </div>
          <div>
            <Select placeholder="Day" onChange={(ev) => setDay(ev)}>
              <Option value={4}>All</Option>
              <Option value={1}>Day 1</Option>
              <Option value={2}>Day 2</Option>
              <Option value={3}>Day 3</Option>
            </Select>
          </div>
        </div>
        <div className="boxDiv">
  <div className="innerboxDiv">
    {Array.isArray(props.cowSearchData) ? (
      props.cowSearchData.map((a, i) => (
        <div className="hissaBox" key={a._id}>
          <div
            className="cow"
            onClick={() => {
              setEditObj({ ...a, hissaNumber: i + 1 });
              showModal();
            }}
          >
            <div className="innerCow">
              <h4>Name: {a.hissadarName}</h4>
              <h6>
                Hissa Number:{" "}
                <span className="hissaNumber">
                  {a.sequencenNumber}/{i + 1}
                </span>
              </h6>
              <p className="phoneNumber">
                Phone Number: {a.contact1 ? a.contact1 : a.contact2}
              </p>
            </div>
          </div>
          <div className="hissaBtn">
            <button
              className="editBtn"
              onClick={() => {
                setEditObj({ ...a, hissaNumber: i + 1 });
                showModal();
              }}
            >
              Edit
            </button>
            <Popconfirm
              title="Are you sure to delete this hissa"
              onConfirm={(e) => {
                confirm(e);
                deleteHissaFn(a._id);
              }}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button className="deleteBtn">Delete</button>
            </Popconfirm>
            {/* <button className="printBtn">Print</button> */}
          </div>
        </div>
      ))
    ) : (
      <p className="noDataMessage"> Seach Hissa by Hissedaar Name </p>
    )}
  </div>
</div>

      </div>
    </div>
  );
};
const mapStateToProps = ({ store }) => ({
  allGoatData: store.allGoatData,
  cowSearchData: store.cowSearchData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      allHissa,
      deleteHissa,
      cowSearch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HissaCow);
