import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import './layout.scss'
import { DownOutlined } from "@ant-design/icons";
import { withRouter } from "react-router";
import "./layout.css";

const menu = (
  <Menu>
    <Menu.Item>
      <p
        onClick={() => {
          localStorage.removeItem("name");
          localStorage.removeItem("login");
          window.location.href = "/";
        }}
      >
        Log out
      </p>
    </Menu.Item>
  </Menu>
);
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

function LayoutApp(props) {
  let [collapsed, setCollapsed] = useState(true);
  let [page, setPage] = useState("");

  let [userName, setUserName] = useState("");

  useEffect(() => {
    setUserName(localStorage.getItem("name"));
  }, []);

  function toggle() {
    setCollapsed(!collapsed);
  }

  useEffect(() => {
    setPage(props.history.location.pathname.split("/")[1].split("-").join(" "));
  }, [props]);

  return (
    <Layout>
      <Sider trigger={null}>
        <div className="logo layOutLogo">
          <h2>Logo</h2>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[page]}>
          <Menu.Item
            onClick={() => setPage("dashboard")}
            key="dashboard"
            icon={<UserOutlined />}
            // onClick={() => {
            //     props.history.push('/dashboard')
            // }}
          >
            Dashboard
          </Menu.Item>
          <SubMenu icon={<UserOutlined />} title="Hissa management">
            <Menu.Item
              // onClick={() => setPage('cow')}
              key="cow"
              onClick={() => {
                props.history.push("/hissa-management/cow");
              }}
            >
              Cow
            </Menu.Item>
            <Menu.Item
              // onClick={() => setPage('goat')}
              key="goat"
              onClick={() => {
                props.history.push("/hissa-management/goat");
              }}
            >
              Goat
            </Menu.Item>
            <Menu.Item
              // onClick={() => setPage('hissa')}
              key="hissa"
              onClick={() => {
                props.history.push("/hissa-management/hissa");
              }}
            >
              Hissa
            </Menu.Item>
          </SubMenu>
          <SubMenu icon={<UserOutlined />} title="report">
            <Menu.Item
              // onClick={() => setPage('cowReport')}
              key="cowReport"
              onClick={() => {
                props.history.push("/report/cow");
              }}
            >
              Cow Report
            </Menu.Item>
            <Menu.Item
              // onClick={() => setPage('goatReport')}
              key="goatReport"
              onClick={() => {
                props.history.push("/report/goat");
              }}
            >
              Goat Report
            </Menu.Item>
            <Menu.Item
              //  onClick={() => setPage('riderReport')}
              key="riderReport"
              onClick={() => {
                props.history.push("/report/rider");
              }}
            >
              Rider Report
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background shadow"
          style={{ padding: 0 }}
        >
          <h3>{page}</h3>
          <div className="user">
            <Avatar size="large" icon={<UserOutlined />} />
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {userName} <DownOutlined />
              </a>
            </Dropdown>
            ,
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = ({ store }) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LayoutApp));
