import React, { useState } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";

export const AddGoat = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [day, setDay] = useState(1);

  //for contribution
  return (
    <>
      <button className="addCowBtn" onClick={showModal}>
        Add Goat
      </button>
      <Modal
        title="Add Goat"
        className="addCowModal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
      >
        <div className="addCowForm">
          <table>
            <tbody>
              <tr>
                <td className="cowNumber">Goat Number</td>
                <td>
                  <input type="number" />
                </td>
              </tr>
              <tr>
                <td>Day</td>
                <td className="daySelect">
                  <div
                    className={day === 1 ? "daysDiv active" : "daysDiv"}
                    onClick={() => setDay(1)}
                  >
                    1
                  </div>
                  <div
                    className={day === 2 ? "daysDiv active" : "daysDiv"}
                    onClick={() => setDay(2)}
                  >
                    2
                  </div>
                  <div
                    className={day === 3 ? "daysDiv active" : "daysDiv"}
                    onClick={() => setDay(3)}
                  >
                    3
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddGoat);
