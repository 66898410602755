import { Space, Spin } from "antd";
import "antd/dist/antd.css";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, HashRouter as Router, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import LayoutApp from "../../layout";
import LoginForm from "../Login/Login";
import Cow from "../hissaManagement/cow/Cow";
import Hissa from "../hissaManagement/cow/Hissa";
import Goat from "../hissaManagement/goat/Goat";
import HissaCow from "../hissaManagement/hissa/HissaCow";
import Home from "../home";
import CowReport from "../report/cowReport/CowReport";
import GoatReport from "../report/goatReport/GoatReport";
import RiderReport from "../report/riderReport/RiderReport";
function App(props) {
  useEffect(() => {
    if (!localStorage.getItem("login") && window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }, []);

  return (
    <Router>
      <Switch>
        {/* Route for Login */}
        <Route exact path="/">
          {/* Redirect to Dashboard if authenticated */}
          {localStorage.getItem("login") ? <Redirect to="/dashboard" /> : <LoginForm />}
        </Route>

        {/* Protected Routes */}
        {localStorage.getItem("login") ? (
          <LayoutApp>
            <Switch>
              <Route exact path="/dashboard" component={Home} />
              <Route exact path="/hissa-management/cow" component={Cow} />
              <Route
              exact
              path="/hissa-management/cow/cowDetail/:cowId/:cowNumber/:day"
              component={Hissa}
            />
            <Route exact path="/hissa-management/goat" component={Goat} />
            <Route exact path="/hissa-management/hissa" component={HissaCow} />
            <Route exact path="/report/cow" component={CowReport} />
            <Route exact path="/report/goat" component={GoatReport} />
            <Route exact path="/report/rider" component={RiderReport} />
            </Switch>
            {/* Loading indicator */}
            {props.dashboardLoading ? (
              <div className="loader">
                <Space>
                  <Spin size="large" />
                </Space>
                <h5>Loading</h5>
              </div>
            ) : null}
          </LayoutApp>
        ) : (
          // Redirect to Login if not authenticated
          <Redirect to="/" />
        )}

        {/* Redirect to Login for unknown routes */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = ({ store }) => ({
  dasboardLoading: store.dasboardLoading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
