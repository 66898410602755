import { Modal, Select } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { addCow, cowSearch } from "../../../modules/actions";

const AddCow = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cowNumber, setCowNumber] = useState();
  const [day, setDay] = useState(1);
  const [area, setArea] = useState();
  const { Option } = Select;

  const showModal = () => {
    setIsModalVisible(true);
  };
  //for contribution
  const handleOk = () => {
    setIsModalVisible(false);
    let obj = {
      sequencenNumber: Number(cowNumber),
      day: day,
      year: new Date().getFullYear(),
      area: area,
    };
    props.addCow(obj);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <button className="addCowBtn" onClick={showModal}>
        Add Cow
      </button>
      <Modal
        footer={[
          <button type="button" class="ant-btn" onClick={handleCancel}>
            <span>Cancel</span>
          </button>,
          <button
            type="button"
            class="ant-btn ant-btn-primary"
            onClick={handleOk}
            disabled={cowNumber ? false : true}
          >
            <span>Create</span>
          </button>,
        ]}
        title="Add Cow"
        className="addCowModal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
      >
        <div className="addCowForm">
          <table>
            <tbody>
              <tr>
                <td className="cowNumber">Cow Number</td>
                <td>
                  <input
                    type="number"
                    onChange={(ev) => setCowNumber(ev.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Day</td>
                <td className="daySelect">
                  <div
                    className={day === 1 ? "daysDiv active" : "daysDiv"}
                    onClick={() => setDay(1)}
                  >
                    1
                  </div>
                  <div
                    className={day === 2 ? "daysDiv active" : "daysDiv"}
                    onClick={() => setDay(2)}
                  >
                    2
                  </div>
                  <div
                    className={day === 3 ? "daysDiv active" : "daysDiv"}
                    onClick={() => setDay(3)}
                  >
                    3
                  </div>
                </td>
              </tr>
              <tr>
                <td>Area</td>
                <td>
                  <Select
                    placeholder="Select Area"
                    onChange={(value) => setArea(value)}
                    className="modernSelect"
                  >
                    <Option value="Munir Fountain">Munir Fountain</Option>
                    <Option value="Johar 19">Johar 19</Option>
                    <Option value="Karachi">Karachi</Option>
                  </Select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ store }) => ({
  cowSearchData: store.cowSearchData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cowSearch,
      addCow,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCow));
