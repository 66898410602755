import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AddGoat } from "./AddGoat";
import { Select, Popconfirm, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddHissa from "./AddHissa";
import goatSlipHeader from "./../../../Images/goatSlipHeader.jpg";
import { allGoats, deleteAnimal, cowSearch } from "../../../modules/actions";
import { bindActionCreators } from "redux";

const { Option } = Select;
const Goat = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  let [search, setSearch] = useState("");
  let [day, setDay] = useState(4);
  let [data, setData] = useState();
  const [copyObj, setCopyObj] = useState("");
  const [copied, settCopied] = useState(false);

  const [editObj, setEditObj] = useState();
  function confirm(e, id) {
    deletGoat(id);
  }

  function cancel(e) {
    message.error("Click on No");
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditObj(false);
  };

  useEffect(() => {
    props.allGoats(Number(day));
  }, [day]);
  useEffect(() => {
    props.cowSearch(search, "Goat");
  }, [search]);
  useEffect(() => {
    if (search) {
      setData(props.cowSearchData);
    } else {
      setData(props.allGoatData);
    }
  }, [search, props]);

  const deletGoat = (id) => {
    let obj = {
      id: id,
    };
    props.deleteAnimal(obj);
  };
  return (
    <div className="CowPage">
      <div className="innerCow">
        <div className="toolBar">
          <h3>Goat Management</h3>
          <div className="toolBtn">
            <AddHissa
              clipHeader={goatSlipHeader}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              showModal={showModal}
              handleOk={handleOk}
              handleCancel={handleCancel}
              editObj={editObj}
              copyObj={copyObj}
              setCopyObj={setCopyObj}
              copied={copied}
              settCopied={settCopied}
            />
          </div>
        </div>
        <div className="filterDiv">
          <div className="search">
            <SearchOutlined />
            <input
              placeholder="Enter Goat No"
              onChange={(ev) => setSearch(ev.target.value)}
            />
          </div>
          <div>
            <Select placeholder="Day" onChange={(ev) => setDay(ev)}>
              <Option value={4}>All</Option>
              <Option value={1}>Day 1</Option>
              <Option value={2}>Day 2</Option>
              <Option value={3}>Day 3</Option>
            </Select>
          </div>
        </div>
        <div className="boxDiv">
          <div className="innerboxDiv">
            {data &&
              data.map((a, i) => {
                return (
                  <div className="hissaBox">
                    <div
                      className="cow"
                      onClick={() => {
                        setEditObj(a);
                        showModal();
                      }}
                    >
                      <div className="innerCow">
                        <h3>Goat No: {a.sequencenNumber}</h3>
                        <h4>Name: {a.hissadarName}</h4>
                        <p className="phoneNumber">
                          Phone Number: {a.contact1 ? a.contact1 : a.contact2}
                        </p>
                        <h6>Year: {a.year}</h6>
                        <p className="phoneNumber">Payment: {a.isPaid}</p>
                      </div>
                    </div>
                    <div className="hissaBtn">
                      <button
                        className="editBtn"
                        onClick={() => {
                          setEditObj(a);
                          showModal();
                        }}
                      >
                        Edit
                      </button>
                      <Popconfirm
                        title="Are you sure to delete this hissa"
                        onConfirm={(e) => {
                          confirm(e, a._id);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button className="deleteBtn">Delete</button>
                      </Popconfirm>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ store }) => ({
  allGoatData: store.allGoatData,
  cowSearchData: store.cowSearchData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      allGoats,
      deleteAnimal,
      cowSearch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Goat);
