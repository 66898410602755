import { message } from 'antd';
import Axios from 'axios';



// product loader true

// get dashbaord states all
function dashboardLoader(a) {
    return dispatch => {
        dispatch(dasboardLoading(a));
    };
}
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const dasboardLoading = (products) => {
    return {
        type: DASHBOARD_LOADING,
        payload: products
    }
}

export function dashboard() {
    return dispatch => {


        dispatch(dashboardLoader(true))
        Axios({
            method: 'get',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/getDashboardStatistics`,
            url: `http://65.0.3.171:3008/api/users/getDashboardStatistics`,
            headers: {
                'Authorization': `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjI0MDA1OTYyLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm9yaWdfaWF0IjoxNjIxNDEzOTYyfQ.-QIT-1giQenwtHItU0pn01gCncZBd1RqjHQDTyYHKaY`,
                "Content-Security-Policy": "upgrade-insecure-requests"
            },
        }).then((res) => {
            // console.log(res.data)
            dispatch(fetchDashboardSuccess(res.data))
            dispatch(dashboardLoader(false))
        })
            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });


        return {}
    };
}
export const FETCH_DASHBAORD_SUCCESS = 'FETCH_DASHBAORD_SUCCESS';
export const fetchDashboardSuccess = (data) => ({
    type: FETCH_DASHBAORD_SUCCESS,
    payload: data
});


// get cow report all
function cowReportLoader() {
    return dispatch => {
        dispatch(cowReportLoading());
    };
}
export const COWREPORT_LOADING = 'COWREPORT_LOADING';
export const cowReportLoading = (products) => {
    return {
        type: DASHBOARD_LOADING,
        payload: {}
    }
}

export function cowReports(day, area) {
    return dispatch => {
      dispatch(dashboardLoader(true));
    //   let url = `${process.env.REACT_APP_PUBLIC_SERVER_URL}/cowReport?day=${day}`;
    let url = `http://65.0.3.171:3008/api/users/cowReport?day=${day}`;
  
      if (area !== "All") {
        url += `&area=${area}`;
      }
  
      Axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjI0MDA1OTYyLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm9yaWdfaWF0IjoxNjIxNDEzOTYyfQ.-QIT-1giQenwtHItU0pn01gCncZBd1RqjHQDTyYHKaY`,
          "Content-Security-Policy": "upgrade-insecure-requests"
        },
      }).then((res) => {
        dispatch(fetchCowReportSuccess(res.data));
        dispatch(dashboardLoader(false));
      }).catch(function (error) {
        let err = '';
        err = error.response && error.response.data.message;
        dispatch(dashboardLoader(false));
        message.error(err);
      });
  
      return {};
    };
  }
  
export const FETCH_COWREPORT_SUCCESS = 'FETCH_COWREPORT_SUCCESS';
export const fetchCowReportSuccess = (data) => ({
    type: FETCH_COWREPORT_SUCCESS,
    payload: data
});


// search cow
export function cowSearch(cowNumber, type, hissa) {
    if(type=='hissa'){
        type = 'Cow';
        hissa =true
    }
    return dispatch => {
      Axios({
        method: 'get',
        // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/searchCow?textToSearch=${cowNumber}&type=${type}&hissa=${hissa}`,
        url: `http://65.0.3.171:3008/api/users/searchCow?textToSearch=${cowNumber}&type=${type}&hissa=${hissa}`,

        headers: {
          'Authorization': `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjI0MDA1OTYyLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm9yaWdfaWF0IjoxNjIxNDEzOTYyfQ.-QIT-1giQenwtHItU0pn01gCncZBd1RqjHQDTyYHKaY`,
          "Content-Security-Policy": "upgrade-insecure-requests"
        },
      })
      .then((res) => {
        //console.log(JSON.stringify(res.data),'ssssssssssssssssssss')
        dispatch(fetchCowSearchSuccess(res.data));
      })
      .catch(function (error) {
        let err = '';
        err = error.response && error.response.data.message;
        message.error(err);
      });
  
      return {};
    };
  }
  
export const FETCH_COWSEARCH_SUCCESS = 'FETCH_COWSEARCH_SUCCESS';
export const fetchCowSearchSuccess = (data) => ({
    type: FETCH_COWSEARCH_SUCCESS,
    payload: data
});





// add Cow 
function addCowLoader() {
    return dispatch => {
        dispatch(addCowLoading());
    };
}
export const AddCow_LOADING = 'AddCow_LOADING';
export const addCowLoading = (products) => {
    return {
        type: DASHBOARD_LOADING,
        payload: {}
    }
}

export function addCow(obj) {
    return dispatch => {
      dispatch(dashboardLoader(true));
  
      var data = JSON.stringify(obj);
  
      var config = {
        method: 'post',
        // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/createCow`,
        url: `http://65.0.3.171:3008/api/users/createCow`,

        

        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
  
      return Axios(config)
        .then(function (response) {
          dispatch(allCows('Pending', 4));
          dispatch(cowReports(4));
          dispatch(dashboardLoader(false));
          return response.data; // Return the response data
        })
        .catch(function (error) {
          let err = '';
          err = error.response && error.response.data.message;
          dispatch(dashboardLoader(false));
          message.error(err);
          throw error; // Throw the error to propagate it to the caller
        });
    };
  }
  

// get All Cow
export function allCows(filter, day, area) {
    return (dispatch) => {
      dispatch(dashboardLoader(true));
  
    //   let url = `${process.env.REACT_APP_PUBLIC_SERVER_URL}/getAllCows?filter=${filter}&day=${day}`;
    let url = `http://65.0.3.171:3008/api/users/getAllCows?filter=${filter}&day=${day}`;

      if (area !== "All") {
        url += `&area=${area}`;
      }
  
      Axios({
        method: 'get',
        url: url,
        headers: {
          'Authorization': `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjI0MDA1OTYyLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm9yaWdfaWF0IjoxNjIxNDEzOTYyfQ.-QIT-1giQenwtHItU0pn01gCncZBd1RqjHQDTyYHKaY`,
          "Content-Security-Policy": "upgrade-insecure-requests"
        },
      }).then((res) => {
        dispatch(fetchAllCowsSuccess(res.data));
        dispatch(dashboardLoader(false));
      }).catch(function (error) {
        let err = '';
        err = error.response && error.response.data.message;
        dispatch(dashboardLoader(false));
        message.error(err);
      });
  
      return {};
    };
  }
  
export const FETCH_ALLCOWS_SUCCESS = 'FETCH_ALLCOWS_SUCCESS';
export const fetchAllCowsSuccess = (data) => ({
    type: FETCH_ALLCOWS_SUCCESS,
    payload: data
});

// export const FETCH_DASHBAORD_SUCCESS = 'FETCH_DASHBAORD_SUCCESS';
// export const AddCowSuccess = (data) => ({
//     type: FETCH_DASHBAORD_SUCCESS,
//     payload: data
// });

// get All Hiisa
export function allHissa(id) {
    return dispatch => {


        dispatch(dashboardLoader(true))

        Axios({
            method: 'get',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/getAllCowsHissa?cowId=${id}`,
            url: `http://65.0.3.171:3008/api/users/getAllCowsHissa?cowId=${id}`,

            headers: {
                'Authorization': `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjI0MDA1OTYyLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm9yaWdfaWF0IjoxNjIxNDEzOTYyfQ.-QIT-1giQenwtHItU0pn01gCncZBd1RqjHQDTyYHKaY`,
                "Content-Security-Policy": "upgrade-insecure-requests"
            },
        }).then((res) => {
            dispatch(fetchAllHissaSuccess(res.data))
            // dispatch(cowReportLoader(false))
            dispatch(dashboardLoader(false))

        })
            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });


        return {}
    };
}
export const FETCH_ALLHISSA_SUCCESS = 'FETCH_ALLHISSA_SUCCESS';
export const fetchAllHissaSuccess = (data) => ({
    type: FETCH_ALLHISSA_SUCCESS,
    payload: data
});

// add Hissa
export function addHissa(obj) {
    return dispatch => {


        dispatch(dashboardLoader(true))

        var data = JSON.stringify(obj);

        var config = {
            method: 'post',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/addHissa`,
            url: `http://65.0.3.171:3008/api/users/addHissa`,

            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                dispatch(allHissa(obj.cowId))
                dispatch(dashboardLoader(false))


            })

            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });
        return {}
    };
}
// update Hissa
export function updateHissa(obj, cowId) {
    return dispatch => {


        dispatch(addCowLoader(true))
        var data = JSON.stringify(obj);

        var config = {
            method: 'PUT',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/editHissa`,
            url: `http://65.0.3.171:3008/api/users/editHissa`,
            
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                dispatch(allHissa(cowId))
                dispatch(dashboardLoader(false))


            })

            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });
        return {}
    };
}

// delete Hissa
export function deleteHissa(obj, cowId) {
    return dispatch => {

        dispatch(dashboardLoader(true))

        var data = JSON.stringify(obj);

        var config = {
            method: 'PUT',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/deleteHissa`,
            url: `http://65.0.3.171:3008/api/users/deleteHissa`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                dispatch(allHissa(cowId))

                dispatch(dashboardLoader(false))

            })

            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });
        return {}
    };
}


// get All goat
export function allGoats(day) {
    return dispatch => {


        dispatch(dashboardLoader(true))

        Axios({
            method: 'get',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/getAllgoats?day=${day}`,
            url: `http://65.0.3.171:3008/api/users/getAllgoats?day=${day}`,
            
            headers: {
                'Authorization': `JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjI0MDA1OTYyLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm9yaWdfaWF0IjoxNjIxNDEzOTYyfQ.-QIT-1giQenwtHItU0pn01gCncZBd1RqjHQDTyYHKaY`,
                "Content-Security-Policy": "upgrade-insecure-requests"
            },
        }).then((res) => {
            dispatch(fetchAllGoatSuccess(res.data))
            // dispatch(cowReportLoader(false))
            dispatch(dashboardLoader(false))

        })
            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });


        return {}
    };
}
export const FETCH_ALLGOATS_SUCCESS = 'FETCH_ALLGOATS_SUCCESS';
export const fetchAllGoatSuccess = (data) => ({
    type: FETCH_ALLGOATS_SUCCESS,
    payload: data
});


// add goat
export function addGoat(obj) {
    return dispatch => {


        dispatch(dashboardLoader(true))

        var data = JSON.stringify(obj);

        var config = {
            method: 'post',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/createGoat`,
            url: `http://65.0.3.171:3008/api/users/createGoat`,

            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                dispatch(allGoats(4))
                dispatch(dashboardLoader(false))

            })

            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });
        return {}
    };
}

// update goat
export function editGoat(obj) {
    return dispatch => {


        dispatch(dashboardLoader(true))

        var data = JSON.stringify(obj);

        var config = {
            method: 'put',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/editGoat`,
            url: `http://65.0.3.171:3008/api/users/editGoat`,
            
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                dispatch(allGoats(4))
                dispatch(dashboardLoader(false))


            })
            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });
        return {}
    };
}

// delete goat/cow
export function deleteAnimal(obj) {
    return dispatch => {

        dispatch(dashboardLoader(true))

        var data = JSON.stringify(obj);

        var config = {
            method: 'PUT',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/deleteAnimal`,
            url: `http://65.0.3.171:3008/api/users/deleteAnimal`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                dispatch(allGoats(4))
                dispatch(allCows('Pending', 4))
                dispatch(dashboardLoader(false))


            })
            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });
        return {}
    };
}


export function Login(obj) {
    return dispatch => {

        dispatch(dashboardLoader(true))

        var data = JSON.stringify(obj);

        var config = {
            method: 'post',
            // url: `${process.env.REACT_APP_PUBLIC_SERVER_URL}/login`,
            url: `http://65.0.3.171:3008/api/users/login`,


            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        Axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));

                localStorage.setItem('login', true)
                localStorage.setItem('name', obj.username)
                window.location.href = '/dashboard'
                dispatch(dashboardLoader(false))
                console.log('success')
            })
            .catch(function (error) {
                let err = '';
                err = error.response && error.response.data.message
                dispatch(dashboardLoader(false))

                message.error(err);
            });
        return {}
    };
}


// Copy Paste
export function copyPaste(obj) {
    return dispatch => {
        if (obj) {
        let copyObj = {}
        copyObj = obj
            dispatch(CopyData(copyObj))
        }
    }
}

export const Copy_PASTE = 'Copy_PASTE';
export const CopyData = (data) => ({
    type: Copy_PASTE,
    payload: data
});