import { Pagination, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { allGoats } from "../../../modules/actions";
import "./goatReport.css";
// import exportFromJSON from 'export-from-json'
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const { Option } = Select;

const GoatReport = (props) => {
  let [data, setData] = useState();
  let [day, setDay] = useState(1);
  let [page, setPage] = useState(1);
  let [pageLength, setPageLength] = useState();

  function onChange(value) {
    setDay(value);
  }

  const paginate = (pageNo) => {
    setPage(pageNo);
  };

  useEffect(() => {
    props.allGoats(day);
  }, [day]);

  useEffect(() => {
    if (props.allGoatData) {
      setPageLength(props.allGoatData.filter((a, i) => a.day === day).length);
      setData(
        props.allGoatData.sort((a, b) => {
          return a.sequencenNumber - b.sequencenNumber;
        })
      );
    }
  }, [props, day]);

  return (
    <div className="reportTable">
      <Select
        showSearch
        style={{ width: "100%", marginBottom: "10px" }}
        placeholder="Select Cow Number"
        optionFilterProp="children"
        onChange={onChange}
        defaultValue={day}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onSearch={onSearch}
        // filterOption={(input, option) =>
        //     console.log(option)
        // }
      >
        {[1, 2, 3, 4].map((a, i) => {
          return <Option value={a}>{a === 4 ? `All` : a}</Option>;
        })}
      </Select>

      <table id="table-to-xls" style={{ width: "7.66in" }}>
        <thead>
          <tr className="mainHeading">
            <th
              style={{
                backgroundColor: "#BE7976",
                fontSize: "24px",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
              colSpan={10}
            >
              Qurbani Booking Goat -{" "}
              {day === 1
                ? `First Day`
                : day === 2
                ? `Second Day`
                : day === 3
                ? `Third Day`
                : day === 4
                ? `All`
                : `First Day`}
            </th>
          </tr>
          <tr>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Rcpt
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Addditional
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Addditional Information
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Expected Delivery
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              P/N
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Name
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Address
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Paid /<br /> Unpaid
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Tel
            </th>
            <th
              style={{
                backgroundColor: "#BE7976",
                height: "50px",
                textAlign: "center",
                border: "1px solid black",
                verticalAlign: "middle",
              }}
            >
              Mobile
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((a, i) => {
              return (
                <tr>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {i + 1}
                  </td>
                  {/* <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.specialInstruction &&
                      Object.keys(a.specialInstruction)
                        .filter(
                          (c, k) =>
                            a.specialInstruction[c] &&
                            a.specialInstruction[c] !== "false" &&
                            c !== "zabihha"
                        )
                        .map((d, l, arr) => {
                          return d !== "zabihha" ? (
                            l < arr.length - 1 ? (
                              l % 2 === 0 ? (
                                <React.Fragment key={l}>
                                  {`${d}- `}
                                </React.Fragment>
                              ) : (
                                `${d}-  `
                              )
                            ) : (
                              d
                            )
                          ) : (
                            ""
                          );
                        })}
                  </td> */}
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.specialInstruction &&
                      Object.keys(a.specialInstruction)
                        .filter((c) => {
                          // Handle "raan1" and "raan2" cases
                          if (c === "raan1" || c === "raan2") {
                            if (
                              a.specialInstruction["raan1"] &&
                              a.specialInstruction["raan2"]
                            ) {
                              return c === "raan2";
                            } else if (
                              a.specialInstruction["raan1"] ||
                              a.specialInstruction["raan2"]
                            ) {
                              return c === "raan1";
                            } else {
                              return false;
                            }
                          }

                          // Handle "dast1" and "dast2" cases
                          if (c === "dast1" || c === "dast2") {
                            if (
                              a.specialInstruction["dast1"] &&
                              a.specialInstruction["dast2"]
                            ) {
                              return c === "dast2";
                            } else if (
                              a.specialInstruction["dast1"] ||
                              a.specialInstruction["dast2"]
                            ) {
                              return c === "dast1";
                            } else {
                              return false;
                            }
                          }

                          // Filter out falsy values and "zabihha"
                          return (
                            a.specialInstruction[c] &&
                            a.specialInstruction[c] !== "false" &&
                            c !== "zabihha"
                          );
                        })
                        .map((d, l, arr) => {
                          return d !== "zabihha" ? (
                            l < arr.length - 1 ? (
                              l % 2 === 0 ? (
                                <React.Fragment
                                  key={l}
                                >{`${d}- `}</React.Fragment>
                              ) : (
                                `${d}-  `
                              )
                            ) : (
                              d
                            )
                          ) : (
                            ""
                          );
                        })}
                  </td>

                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.additionalInformation}
                  </td>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.expectedDelivery}
                  </td>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.sequencenNumber}
                  </td>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.hissadarName}
                  </td>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.address &&
                      ` 
                                        ${
                                          a.address.houseNumber
                                            ? `House No ${a.address.houseNumber}`
                                            : ""
                                        } 
                                        ${
                                          a.address.street
                                            ? `${a.address.street}`
                                            : ""
                                        } 
                                        `}
                    <br />
                    {a.address &&
                      `
                                         ${
                                           a.address.nearBy
                                             ? `Near ${a.address.nearBy}`
                                             : ""
                                         } 
                                        ${
                                          a.address.area
                                            ? `Area ${a.address.area}`
                                            : ""
                                        } 
                                        `}
                  </td>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.isPaid}
                  </td>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.contact2}
                  </td>
                  <td
                    style={{
                      height: "50px",
                      textAlign: "center",
                      border: "1px solid black",
                      verticalAlign: "middle",
                    }}
                  >
                    {a.contact1}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="pagePrint">
        <Pagination
          defaultCurrent={1}
          total={pageLength}
          pageSize={7}
          onChange={(e) => paginate(e)}
        />
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Download as XLS"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ store }) => ({
  allGoatData: store.allGoatData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      allGoats,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(GoatReport);
