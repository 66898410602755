// import {
//   DASHBOARD_LOADING, FETCH_DASHBAORD_SUCCESS,FETCH_COWREPORT_SUCCESS, COWREPORT_LOADING

// } from './actions';

const initialState = {
  dasboardLoading: false,
  dashboard: false,
  cowReportLoading: false,
  cowReportData: false,
  cowSearchData: true,
  allCowData: false,
  allHissaData:false,
  allGoatData:false,
  copyObjData:false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'DASHBOARD_LOADING':
      // console.log(action)
      return {
        ...state,
        dasboardLoading: action.payload,
      };
    case 'FETCH_DASHBAORD_SUCCESS':
      return {
        ...state,
        dashboard: action.payload.data
      };
    case 'COWREPORT_LOADING':
      return {
        ...state,
        cowReportLoading: action.payload,
      };
    case 'FETCH_COWREPORT_SUCCESS':
      // console.log( action)
      return {
        ...state,
        cowReportData: action.payload.cow
      };
    case 'FETCH_COWSEARCH_SUCCESS':
      // console.log( action)
      return {
        ...state,
        cowSearchData: action.payload.cow
      };
    case 'FETCH_ALLCOWS_SUCCESS':
      // console.log(action)
      return {
        ...state,
        allCowData: action.payload.cow
      };
      case 'FETCH_ALLHISSA_SUCCESS':
      // console.log(action)
      return {
        ...state,
        allHissaData: action.payload.hissa
      };
        case 'FETCH_ALLGOATS_SUCCESS':
      // console.log(action)
      return {
        ...state,
        allGoatData: action.payload.goats
      };
      case 'Copy_PASTE':
        //console.log(action)
        return {
          ...state,
          copyObjData: action.payload
        };
    default:
      return state
  }
}

