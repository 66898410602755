import { Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  addGoat,
  copyPaste,
  dashboard,
  editGoat,
} from "../../../modules/actions";
import goatRate from "./../../../Images/3.png";

const AddHissa = (props) => {
  const [name, setName] = useState("");
  const [sonOf, setSonOf] = useState("");
  const [street, setStreet] = useState("");
  const [ptcl, setPtcl] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [bookBy, setBookBy] = useState("");
  const [expectedDelivery, setExpectedDelivery] = useState("");
  const [to, setTo] = useState("");
  const [paya, setPaya] = useState(false);
  const [siri, setSiri] = useState(false);
  const [maghaz, setMaghaz] = useState(false);
  const [zaban, setZaban] = useState(false);
  const [raan1, setRaan1] = useState(false);
  const [raan2, setRaan2] = useState(false);
  const [dasti1, setDasti1] = useState(false);
  const [dasti2, setDasti2] = useState(false);
  const [slipFullDate, setSlipFullDate] = useState("");
  const [slipNo, setSlipNo] = useState("1445");
  const [goatNo, setGoatNo] = useState("");
  const [HouseNo, setHouseNo] = useState("");
  const [Area, setArea] = useState("");
  const [NearBy, setNearBy] = useState("");
  const [hissaDay, setHissaDay] = useState(1);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [edit, setEdit] = useState("");
  const [goatId, setGoatId] = useState("");
  const [markedAsPaid, setMarkedAsPaid] = useState("Unpaid");

  const handleCheckboxChange = () => {
    const newStatus = markedAsPaid === "Unpaid" ? "Paid" : "Unpaid";
    setMarkedAsPaid(newStatus);
  };

  const addHissa = () => {
    if (edit) {
      let obj = {
        goatId: goatId,
        hissadarName: name,
        hissadarParentName: sonOf,
        address: {
          street: street,
          houseNumber: HouseNo,
          area: Area,
          nearBy: NearBy,
        },
        contact1: mobileNumber,
        contact2: ptcl,
        bookedBy: bookBy,
        expectedDelivery: `${expectedDelivery}-${to}`,
        specialInstruction: {
          paya: paya,
          siri: siri,
          maghaz: maghaz,
          zabaan: zaban,
          raan1: raan1,
          raan2: raan2,
          dast1: dasti1,
          dast2: dasti2,
          zabihha: true,
        },
        additionalInformation: additionalInfo,
        day: Number(hissaDay),
        year: new Date().getFullYear(),
        bookingDate: slipFullDate
          ? new Date(slipFullDate).toISOString()
          : new Date().toISOString(),
        sequencenNumber: goatNo,
        isPaid: markedAsPaid,
      };
      // console.log(obj)
      props.editGoat(obj);
    } else {
      let obj = {
        hissadarName: name,
        hissadarParentName: sonOf,
        address: {
          street: street,
          houseNumber: HouseNo,
          area: Area,
          nearBy: NearBy,
        },
        contact1: mobileNumber,
        contact2: ptcl,
        bookedBy: bookBy,
        expectedDelivery: `${expectedDelivery}-${to}`,
        specialInstruction: {
          paya: paya,
          siri: siri,
          maghaz: maghaz,
          zabaan: zaban,
          raan1: raan1,
          raan2: raan2,
          dast1: dasti1,
          dast2: dasti2,
          zabihha: true,
        },
        additionalInformation: additionalInfo,
        day: Number(hissaDay),
        year: new Date().getFullYear(),
        bookingDate: slipFullDate
          ? new Date(slipFullDate).toISOString()
          : new Date().toISOString(),
        sequencenNumber: goatNo,
        isPaid: markedAsPaid,
      };
      props.addGoat(obj);
    }
  };

  //console.log(props.copyObjData);
  useEffect(() => {
    props.dashboard();

    // props.cowReports()
  }, []);
  useEffect(() => {
    setGoatNo(props.dashboardState.totalGoats + 1);
  }, [props.dashboardState]);
  // console.log(props.dashboardState)

  useEffect(() => {
    props.copyPaste(props.copyObj);
  }, [props.copyObj]);

  const copyFunction = () => {
    let obj = {
      hissadarName: name,
      hissadarParentName: sonOf,
      address: {
        street: street,
        houseNumber: HouseNo,
        area: Area,
        nearBy: NearBy,
      },
      contact1: mobileNumber,
      contact2: ptcl,
      expectedDelivery: `${expectedDelivery}-${to}`,
      specialInstruction: {
        paya: paya,
        siri: siri,
        maghaz: maghaz,
        zabaan: zaban,
        raan1: raan1,
        raan2: raan2,
        dast1: dasti1,
        dast2: dasti2,
        zabihha: true,
      },
      additionalInformation: additionalInfo,
    };
    props.setCopyObj(JSON.stringify(obj));
  };

  const [day, setDay] = useState(1);

  // console.log(day)
  const length2 = (a) => {
    if (a) {
      if (a.length === 1) {
        return `0${a}`;
      } else {
        return a;
      }
    } else {
      return "";
    }
  };
  let [slipDate1, setSlipDate1] = useState("");
  let [slipDate2, setSlipDate2] = useState("");
  let [slipMonth1, setSlipMonth1] = useState("");
  let [slipMonth2, setSlipMonth2] = useState("");

  let [slipYear1, setSlipYear1] = useState("");
  let [slipYear2, setSlipYear2] = useState("");
  let [slipYear3, setSlipYear3] = useState("");
  let [slipYear4, setSlipYear4] = useState("");
  useEffect(() => {
    let fullDate = new Date();
    let date = length2(fullDate.getDate().toString());
    let month = length2((fullDate.getMonth() + 1).toString());
    let year = length2(fullDate.getFullYear().toString());
    setSlipDate1(date[0]);
    setSlipDate2(date[1]);
    setSlipMonth1(month[0]);
    setSlipMonth2(month[1]);
    setSlipYear1(year[0]);
    setSlipYear2(year[1]);
    setSlipYear3(year[2]);
    setSlipYear4(year[3]);
  }, []);
  function dateInputs(getDate) {
    let editDate = getDate ? getDate : new Date();
    let fullDate = new Date(editDate);
    let date = length2(fullDate.getDate().toString());
    let month = length2((fullDate.getMonth() + 1).toString());
    let year = length2(fullDate.getFullYear().toString());
    setSlipDate1(date[0]);
    setSlipDate2(date[1]);
    setSlipMonth1(month[0]);
    setSlipMonth2(month[1]);
    setSlipYear1(year[0]);
    setSlipYear2(year[1]);
    setSlipYear3(year[2]);
    setSlipYear4(year[3]);
  }

  useEffect(() => {
    setSlipFullDate(
      `${slipMonth1 === "0" ? "" : slipMonth1}${slipMonth2}/${
        slipDate1 === "0" ? "" : slipDate1
      }${slipDate2}/${slipYear1}${slipYear2}${slipYear3}${slipYear4}`
    );
  }, [
    slipDate1,
    slipDate2,
    slipMonth1,
    slipMonth2,
    slipYear1,
    slipYear2,
    slipYear3,
    slipYear4,
  ]);

  function printElem() {
    var form = document.getElementById("main").cloneNode(true);
    var checkboxes = form.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        checkbox.setAttribute("checked", "checked");
      } else {
        checkbox.removeAttribute("checked");
      }
    });

    var win = window.open();

    var style = `<style>
    h1,h2,h3,h4,h5,h6,p{
        color:#423d89;
    }
    .AddHissaForm {
        -webkit-print-color-adjust: exact;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }        
    
    .AddHissaForm .daysHissa {
        display: flex;
        align-items: center;
        float: right;
         position: absolute;
        right: 10px;
   }
    .AddHissaForm .daysHissa .daySelect {
         -webkit-print-color-adjust: exact;
        font-size: 14px;
        border: 1px solid black;
        padding: 4px 10px;
        margin-right: 3px;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 4px 5px;
   }
    .AddHissaForm .daysHissa .daySelect.active {
        background-color: #dda9aa;
        color: #423d8a;
        border-color: #dda9aa;
   }
    .AddHissaForm .slipheader {
        width: 80%;
   }
    .AddHissaForm .innerForm {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
   }
    .AddHissaForm .innerForm .hissaInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
   }
    .AddHissaForm .innerForm .hissaInfo h5 {
        margin: 0px;
        display: flex;
        align-items: flex-end;
        margin-right: 8px;
        font-size: 11px;
   }
    .AddHissaForm .innerForm .hissaInfo .date {
        width: 300px;
        padding: 11px 11px 0px;
        display: flex;
        align-items: center;
   }
    .AddHissaForm .innerForm .hissaInfo .smallBoxInput {
        display: flex;
        align-items: center;
        font-size: 15px;
   }
    .AddHissaForm .innerForm .hissaInfo .smallBoxInput input {
        width: 25px;
        height: 25px;
        text-align: center;
        outline: none;
   }
    .AddHissaForm .innerForm .hissaInfo .smallBoxInput p {
        padding: 0px 10px;
        margin: 0;
   }
   
   .AddHissaForm .innerForm .hissaInfo .smallBoxInput.cowNoInput input {
       width: 60px;
   
    }
    .AddHissaForm .innerForm .hissaInfo .cowNo, .AddHissaForm .innerForm .hissaInfo .HissaNo {
        display: flex;
        align-items: center;
   }
    .AddHissaForm .innerForm label {
        display: flex;
        padding: 11px 11px 0px;
   }
    .AddHissaForm .innerForm label input {
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid black;
        font-size: 11px;
   }
    .AddHissaForm .innerForm label h5 {
        margin: 0px;
        display: flex;
        align-items: flex-end;
        margin-right: 8px;
        font-size: 11px;
   }
    .AddHissaForm .innerForm .w75 {
        width: 65%;
   }
    .AddHissaForm .innerForm .w25 {
        width: 25%;
   }
    .AddHissaForm .innerForm .w100 {
        width: 100%;
   }
    .AddHissaForm .innerForm .w100.checkImage {
        display: flex;
        align-items: center;
        justify-content: space-between;
   }
    .AddHissaForm .innerForm .w100.checkImage img {
        width: 180px;
            transform: translateZ(10px);
   }
    .AddHissaForm .innerForm .w50 {
        width: 46%;
   }
    .AddHissaForm .innerForm .w33 {
        width: 30%;
   }
    .AddHissaForm .innerForm .mobNo input {
        width: calc(100% - 110px);
   }
    .AddHissaForm .innerForm .mobNo h5 {
        width: 80px;
   }
    .AddHissaForm .innerForm .bookBy input {
        width: calc(100% - 70px);
   }
    .AddHissaForm .innerForm .bookBy h5 {
        width: 60px;
   }
    .AddHissaForm .innerForm .expected input {
        width: calc(100% - 130px);
   }
    .AddHissaForm .innerForm .expected h5 {
        width: 90px;
   }
    .AddHissaForm .innerForm .houseNo h5 {
        width: 80px;
   }
   .AddHissaForm .innerForm .name .intructInput input {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    width: 750px;
    margin-top: 6px;
    margin-left: 7px;
}
    .AddHissaForm .innerForm .sonof .intructInput input {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    width: 160px;
    margin-top: 6px;
    margin-left: 7px;
    }
    .AddHissaForm .innerForm .street .intructInput input {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    width: 1000px;
    margin-top: 6px;
    margin-left: 12px;
    }
    .AddHissaForm .innerForm .ptcl .intructInput input {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    width: 400px;
    margin-top: 6px;
    margin-left: 5px;
    }
    .AddHissaForm .innerForm .booked .intructInput input {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    width: 180px;
    margin-top: 6px;
    margin-left: 12px;
    }
    .AddHissaForm .innerForm .checkboxs {
        display: flex;
   }
    .AddHissaForm .innerForm .checkboxs h5 {
        display: block;
   }
   .AddHissaForm .innerForm .checkboxs .checkBoxes span {
    display: flex;
    align-items: center;
}
    .AddHissaForm .innerForm .checkboxs .checkBoxes {
        margin-left: 10px;
        padding: 0px !important;
   }
   .AddHissaForm .innerForm .checkboxs .checkBoxes input {
    width:30px
}
    .AddHissaForm .innerForm .checkboxs .instructionBox {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        align-items: center;
        
   }
   .AddHissaForm .innerForm .checkboxs .instructionBox div p {
        margin: 0;
        margin: 0px 10px;
        font-size: 14px;

}
   .AddHissaForm .innerForm .checkboxs .instructionBox span {
font-size: 11px;
} 
    .AddHissaForm .innerForm .confirmation {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 11px;
   }
    .AddHissaForm .innerForm .confirmation h5 {
    font-size:11px;
    margin: 0px;
    }
    .AddHissaForm .innerForm .confirmation p {
        margin: 0px;
   }
    .AddHissaForm .innerForm .confirmation .Category {
   
border-radius: 14px;
border: 2px solid;
width: 93px;
height: 73px;
margin: 0px 10px;

   }
    .AddHissaForm .innerForm .confirmation .Category p {
        margin: 0;
        border-bottom: 2px solid black;
        text-align: center;
        padding: 6px;
        font-size: 12px;

   }
    .AddHissaForm .innerForm .confirmation .Category h6 {
        font-size: 20px;
        margin: 0px;
        text-align: center;
   }
    .AddHissaForm .innerForm .confirmation .signature {
        width: 300px;
        border: 2px solid black;
   }
    .AddHissaForm .innerForm .confirmation .signature .signDiv {
        width: 100%;
        height: 50px;
        text-align: center;
   }
    .AddHissaForm .innerForm .confirmation .signature p {
        text-align: center;
   }
    .AddHissaForm .innerForm .additionInstruction {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: -60px 0px 10px;

   }
    .AddHissaForm .innerForm .additionInstruction h5 {
        margin: 0px;
        display: flex;
        align-items: flex-end;
        margin-right: 8px;
        font-size: 11px;
   }
    .AddHissaForm .innerForm .additionInstruction .intructInput {
        width: calc(100% - 350px);
        padding: 10px 10px 0px;
   }
    .AddHissaForm .innerForm .additionInstruction .intructInput input {
        border: none;
        border-bottom: 1px solid black;
        outline: none;
        width: calc(100% - 150px);
   }
    .AddHissaForm .innerForm .confirmation .website {
        text-align: center;
        width: 300px;
        padding: 10px;
        display: flex;
        justify-content: center;
        font-size: 18px;
margin: 10px 0px 0px;
   }
    .khalein{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -9px;
        }
        .khalein p{
            margin: 0px;    
        }
    
   </style>
    `;
    var htmlContent =
      "<html><head>" +
      style +
      "</head><body>" +
      form.outerHTML +
      "</body></html>";

    win.document.write(htmlContent);
    win.document.close();

    setTimeout(function () {
      win.print();
    }, 700);
  }

  useEffect(() => {
    if (props.editObj) {
      setEdit(true);
      setGoatId(props.editObj && props.editObj._id);
      setName(
        props.editObj && props.editObj.hissadarName
          ? props.editObj.hissadarName
          : ""
      );
      setSonOf(
        props.editObj && props.editObj.hissadarParentName
          ? props.editObj.hissadarParentName
          : ""
      );
      setStreet(
        props.editObj.address && props.editObj.address.street
          ? props.editObj.address.street
          : ""
      );
      setPtcl(
        props.editObj && props.editObj.contact2 ? props.editObj.contact2 : ""
      );
      setMobileNumber(
        props.editObj && props.editObj.contact1 ? props.editObj.contact1 : ""
      );
      setBookBy(
        props.editObj && props.editObj.bookedBy ? props.editObj.bookedBy : ""
      );
      setExpectedDelivery(
        props.editObj && props.editObj.expectedDelivery
          ? props.editObj.expectedDelivery.split("-")[0]
          : ""
      );
      setTo(
        props.editObj && props.editObj.expectedDelivery
          ? props.editObj.expectedDelivery.split("-")[1]
          : ""
      );
      setDasti1(
        props.editObj && props.editObj.specialInstruction.dast1
          ? props.editObj.specialInstruction.dast1
          : false
      );
      setDasti2(
        props.editObj && props.editObj.specialInstruction.dast2
          ? props.editObj.specialInstruction.dast2
          : false
      );
      //for contribution
      setMaghaz(
        props.editObj && props.editObj.specialInstruction.maghaz
          ? props.editObj.specialInstruction.maghaz
          : false
      );
      setPaya(
        props.editObj && props.editObj.specialInstruction.paya
          ? props.editObj.specialInstruction.paya
          : false
      );
      setRaan1(
        props.editObj && props.editObj.specialInstruction.raan1
          ? props.editObj.specialInstruction.raan1
          : false
      );
      setRaan2(
        props.editObj && props.editObj.specialInstruction.raan2
          ? props.editObj.specialInstruction.raan2
          : false
      );
      setSiri(
        props.editObj && props.editObj.specialInstruction.siri
          ? props.editObj.specialInstruction.siri
          : false
      );
      setZaban(
        props.editObj && props.editObj.specialInstruction.zabaan
          ? props.editObj.specialInstruction.zabaan
          : false
      );
      setSlipFullDate(dateInputs(props.editObj && props.editObj.bookingDate));
      setHouseNo(
        props.editObj.address && props.editObj.address.houseNumber
          ? props.editObj.address.houseNumber
          : ""
      );
      setArea(
        props.editObj.address && props.editObj.address.area
          ? props.editObj.address.area
          : ""
      );
      setNearBy(
        props.editObj.address && props.editObj.address.nearBy
          ? props.editObj.address.nearBy
          : ""
      );
      setHissaDay(props.editObj && props.editObj.day ? props.editObj.day : "");
      setAdditionalInfo(
        props.editObj && props.editObj.additionalInformation
          ? props.editObj.additionalInformation
          : ""
      );
      setGoatNo(
        props.editObj && props.editObj.sequencenNumber
          ? props.editObj.sequencenNumber
          : ""
      );
      setMarkedAsPaid(
        props.editObj &&
          props.editObj.isPaid &&
          props.editObj.isPaid === "Unpaid"
          ? "Unpaid"
          : "Paid"
      );
    } else if (!props.editObj) {
      setEdit(false);
      setName("");
      setSonOf("");
      setStreet("");
      setPtcl("");
      setMobileNumber("");
      setBookBy("");
      setExpectedDelivery("");
      setTo("");

      setDasti1(false);
      setDasti2(false);
      setMaghaz(false);
      setPaya(false);
      setRaan1(false);
      setRaan2(false);
      setSiri(false);
      setZaban(false);

      setSlipFullDate("");
      setHouseNo("");
      setArea("");
      setNearBy("");
      setHissaDay(1);
      setAdditionalInfo("");
      setGoatId("");
      setGoatNo(props.dashboardState.totalGoats + 1);
      dateInputs(new Date());
      setMarkedAsPaid("Unpaid");
    }
    // console.log(props.editObj)
  }, [props.editObj]);

  function clearState() {
    setEdit(false);
    setName("");
    setSonOf("");
    setStreet("");
    setPtcl("");
    setMobileNumber("");
    setBookBy("");
    setExpectedDelivery("");
    setTo("");

    setDasti1(false);
    setDasti2(false);
    setMaghaz(false);
    setPaya(false);
    setRaan1(false);
    setRaan2(false);
    setSiri(false);
    setZaban(false);

    setSlipFullDate("");
    setHouseNo("");
    setArea("");
    setNearBy("");
    setHissaDay(1);
    setAdditionalInfo("");
    setGoatId("");
    setGoatNo(props.dashboardState.totalGoats + 1);
    dateInputs(new Date());
    setMarkedAsPaid(false);
  }

  const pasteFunction = () => {
    if (props.copyObjData) {
      let obj = JSON.parse(props.copyObjData);
      setName(obj && obj.hissadarName ? obj.hissadarName : "");
      setSonOf(obj && obj.hissadarParentName ? obj.hissadarParentName : "");
      setStreet(obj.address && obj.address.street ? obj.address.street : "");
      setPtcl(obj && obj.contact2 ? obj.contact2 : "");
      setMobileNumber(obj && obj.contact1 ? obj.contact1 : "");
      setExpectedDelivery(
        obj && obj.expectedDelivery ? obj.expectedDelivery.split("-")[0] : ""
      );
      setTo(
        obj && obj.expectedDelivery ? obj.expectedDelivery.split("-")[1] : ""
      );
      setDasti1(
        obj && obj.specialInstruction.dast1
          ? obj.specialInstruction.dast1
          : false
      );
      setDasti2(
        obj && obj.specialInstruction.dast2
          ? obj.specialInstruction.dast2
          : false
      );
      setMaghaz(
        obj && obj.specialInstruction.maghaz
          ? obj.specialInstruction.maghaz
          : false
      );
      setPaya(
        obj && obj.specialInstruction.paya ? obj.specialInstruction.paya : false
      );
      setRaan1(
        obj && obj.specialInstruction.raan1
          ? obj.specialInstruction.raan1
          : false
      );
      setRaan2(
        obj && obj.specialInstruction.raan2
          ? obj.specialInstruction.raan2
          : false
      );
      setSiri(
        obj && obj.specialInstruction.siri ? obj.specialInstruction.siri : false
      );
      setZaban(
        obj && obj.specialInstruction.zabaan
          ? obj.specialInstruction.zabaan
          : false
      );
      setSlipFullDate(dateInputs(obj && obj.bookingDate));
      setHouseNo(
        obj.address && obj.address.houseNumber ? obj.address.houseNumber : ""
      );
      setArea(obj.address && obj.address.area ? obj.address.area : "");
      setNearBy(obj.address && obj.address.nearBy ? obj.address.nearBy : "");
      setHissaDay(obj && obj.day ? obj.day : "");
      setAdditionalInfo(
        obj && obj.additionalInformation ? obj.additionalInformation : ""
      );
    }
  };
  return (
    <>
      <div className="HissaModalDiv">
        <button className="AddHissaBtn" onClick={props.showModal}>
          Add Goat
        </button>
        <Modal
          footer={[
            <label className="checkboxLabel">
              <input
                type="checkbox"
                checked={markedAsPaid === "Paid"}
                onChange={handleCheckboxChange}
                className="checkboxisPaid"
              />
              Marked as Paid
            </label>,
            <button
              className="cancelBtn"
              onClick={() => {
                props.handleCancel();
                clearState();
              }}
            >
              Cancel
            </button>,
            <CopyToClipboard
              text={props.copyObj}
              onCopy={() => {
                props.settCopied(true);
                copyFunction();
              }}
            >
              <span></span>
            </CopyToClipboard>,

            <CopyToClipboard
              text={props.copyObj}
              onCopy={() => {
                props.settCopied(true);
                copyFunction();
              }}
            >
              <button className="copyBtn">Copy</button>
            </CopyToClipboard>,
            <button className="pasteBtn" onClick={() => pasteFunction()}>
              Paste
            </button>,
            <button
              className="saveBtn"
              onClick={() => {
                props.handleOk();
                addHissa();
                clearState();
              }}
            >
              Save
            </button>,
            <button className="printBtn" onClick={printElem}>
              Print
            </button>,
          ]}
          title="Add Hissa"
          className="AddHissaModal"
          visible={props.isModalVisible}
          onOk={props.handleOk}
          onCancel={() => {
            props.handleCancel();
            clearState();
          }}
          okText="Create"
        >
          <div className="AddHissaForm GoatHissa" id="main">
            <div className="daysHissa">
              <div
                onClick={() => setHissaDay(1)}
                className={hissaDay === 1 ? "daySelect active" : "daySelect"}
              >
                1st Day
              </div>
              <div
                onClick={() => setHissaDay(2)}
                className={hissaDay === 2 ? "daySelect active" : "daySelect"}
              >
                2nd Day
              </div>
              <div
                onClick={() => setHissaDay(3)}
                className={hissaDay === 3 ? "daySelect active" : "daySelect"}
              >
                3rd Day
              </div>
            </div>
            <img className="slipheader" src={props.clipHeader} />
            <div className="innerForm">
              <div className="hissaInfo">
                <div className="date">
                  <h5>DATE:</h5>
                  <div className="dateInputs smallBoxInput">
                    <input
                      value={slipDate1}
                      onChange={(ev) => setSlipDate1(ev.target.value)}
                    />
                    <input
                      value={slipDate2}
                      onChange={(ev) => setSlipDate2(ev.target.value)}
                    />
                    <input
                      value={slipMonth1}
                      onChange={(ev) => setSlipMonth1(ev.target.value)}
                    />
                    <input
                      value={slipMonth2}
                      onChange={(ev) => setSlipMonth2(ev.target.value)}
                    />
                    <input
                      value={slipYear1}
                      onChange={(ev) => setSlipYear1(ev.target.value)}
                    />
                    <input
                      value={slipYear2}
                      onChange={(ev) => setSlipYear2(ev.target.value)}
                    />
                    <input
                      value={slipYear3}
                      onChange={(ev) => setSlipYear3(ev.target.value)}
                    />
                    <input
                      value={slipYear4}
                      onChange={(ev) => setSlipYear4(ev.target.value)}
                    />
                  </div>
                </div>
                <div className="slipNo">
                  <div className="smallBoxInput">
                    <input value={slipNo[0]} />
                    <input value={slipNo[1]} />
                    <input value={slipNo[2]} />
                    <input value={slipNo[3]} />
                    <p>هـ</p>
                  </div>
                </div>
                <div className="cowNo">
                  <h5>Goat No</h5>
                  <div className="smallBoxInput cowNoInput">
                    <input
                      value={goatNo}
                      onChange={(ev) => setGoatNo(ev.target.value)}
                    />
                  </div>
                </div>
              </div>

              <label className="name">
                <h5 className="intructInput">
                  MR/MRs
                  <input
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                  />{" "}
                </h5>
              </label>
              <label className="sonof">
                <h5 className="intructInput">
                  S/o,D/o
                  <input
                    value={sonOf}
                    onChange={(ev) => setSonOf(ev.target.value)}
                  />{" "}
                </h5>
              </label>

              <label className="street">
                <h5 className="intructInput">
                  Street
                  <input
                    value={street}
                    onChange={(ev) => setStreet(ev.target.value)}
                  />{" "}
                </h5>
              </label>

              <label className="ptcl">
                <h5 className="intructInput">
                  PTCL
                  <input
                    value={ptcl}
                    onChange={(ev) => setPtcl(ev.target.value)}
                  />{" "}
                </h5>
              </label>
              <label className="ptcl">
                <h5 className="intructInput">
                  Mobile Number
                  <input
                    value={mobileNumber}
                    onChange={(ev) => setMobileNumber(ev.target.value)}
                  />{" "}
                </h5>
              </label>

              <label className="booked">
                <h5 className="intructInput">
                  Booked By
                  <input
                    value={bookBy}
                    onChange={(ev) => setBookBy(ev.target.value)}
                  />{" "}
                </h5>
              </label>
              <label className="booked">
                <h5 className="intructInput">
                  Expected Delivery
                  <input
                    value={expectedDelivery}
                    onChange={(ev) => setExpectedDelivery(ev.target.value)}
                  />{" "}
                </h5>
              </label>
              <label className="booked">
                <h5 className="intructInput">
                  to
                  <input
                    value={to}
                    onChange={(ev) => setTo(ev.target.value)}
                  />{" "}
                </h5>
              </label>
              <label className="w100 checkImage ">
                <div className="checkboxs">
                  <h5>Special Instructions</h5>
                  <div className="instructionBox">
                    <Checkbox
                      checked={paya}
                      className="checkBoxes"
                      onChange={(ev) => setPaya(ev.target.checked)}
                    >
                      PAYA
                    </Checkbox>
                    <Checkbox
                      checked={siri}
                      className="checkBoxes"
                      onChange={(ev) => setSiri(ev.target.checked)}
                    >
                      SIRI
                    </Checkbox>
                    <Checkbox
                      checked={maghaz}
                      className="checkBoxes"
                      onChange={(ev) => setMaghaz(ev.target.checked)}
                    >
                      MAGHAZ
                    </Checkbox>
                    <Checkbox
                      checked={zaban}
                      className="checkBoxes"
                      onChange={(ev) => setZaban(ev.target.checked)}
                    >
                      ZABAN
                    </Checkbox>
                    <Checkbox
                      checked={raan1}
                      className="checkBoxes"
                      onChange={(ev) => setRaan1(ev.target.checked)}
                    >
                      RAAN
                    </Checkbox>
                    <Checkbox
                      checked={raan2}
                      className="checkBoxes"
                      onChange={(ev) => setRaan2(ev.target.checked)}
                    >
                      RAAN
                    </Checkbox>
                    <Checkbox
                      checked={dasti1}
                      className="checkBoxes"
                      onChange={(ev) => setDasti1(ev.target.checked)}
                    >
                      DASTI
                    </Checkbox>
                    <Checkbox
                      checked={dasti2}
                      className="checkBoxes"
                      onChange={(ev) => setDasti2(ev.target.checked)}
                    >
                      DASTI
                    </Checkbox>
                  </div>
                </div>
                <div>
                  <img
                    src={goatRate}
                    style={{
                      height: "100px",
                      width: "200px",
                      marginRight: "40px",
                      marginTop: "10px",
                      marginBottom: '10px',
                      transform: 'rotate(-1deg)'
                    }}
                  />
                </div>
              </label>
              <div className="confirmation">
                <h5>
                  Kindly return the Pink Copy to the Delivery guy after
                  recieving meal
                </h5>
                <div className="Category">
                  <p>Goat Category</p>
                  <h6>A</h6>
                </div>
                <div>
                  <div className="signature">
                    <div className="signDiv"></div>
                    <p>Reciever Signature</p>
                  </div>
                  <h5 className="website"> IJTAMAIQURBANI.COM </h5>
                </div>
              </div>
              <div className="additionInstruction">
                <h5 className="intructInput">
                  Addition Instruction :{" "}
                  <input
                    value={additionalInfo}
                    onChange={(ev) => setAdditionalInfo(ev.target.value)}
                  />
                </h5>
              </div>
              <div className="khalein">
                <p>*قربانی کی کھالیں الخدمت کو دی جائینگی۔*</p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = ({ store }) => ({
  dashboardState: store.dashboard,
  copyObjData: store.copyObjData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dashboard,
      addGoat,
      editGoat,
      copyPaste,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddHissa));
