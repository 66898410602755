import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Select, Popconfirm, message } from "antd";
import AddHissa from "./AddHissa";
import cowSlipHeader from "./../../../Images/cowSlipHeader.jpg";
import { allHissa, deleteHissa } from "../../../modules/actions";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Hissa = (props) => {
  const [id, setId] = useState();
  const [cowNumber, setCowNumber] = useState();
  const [editObj, setEditObj] = useState();
  const [copyObj, setCopyObj] = useState("");
  const [copied, settCopied] = useState(false);
  function confirm(e) {}
  function cancel(e) {
    message.error("Click on No");
  }
  useEffect(() => {
    setId(props.match.params.cowId);
  }, [props]);
  useEffect(() => {
    if (id) {
      props.allHissa(id);
    }
  }, [id]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditObj(false);
  };

  function deleteHissaFn(idHissa) {
    let obj = {
      hissaId: idHissa,
    };
    props.deleteHissa(obj, id);
  }

  return (
    <div className="hissaPage">
      <div className="innerHissaPage">
        <AddHissa
          clipHeader={cowSlipHeader}
          hissaDetail={props.allHissaData}
          cowNumber={cowNumber}
          cowId={id}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          showModal={showModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          editObj={editObj}
          copyObj={copyObj}
          setCopyObj={setCopyObj}
          copied={copied}
          settCopied={settCopied}
        />
        <div className="boxDiv">
          <div className="innerboxDiv">
            {props.allHissaData &&
              props.allHissaData.map((a, i) => {
                return (
                  <div className="hissaBox">
                    <div
                      className="cow"
                      onClick={() => {
                        setEditObj({ ...a, hissaNumber: i + 1 });
                        showModal();
                      }}
                    >
                      <div className="innerCow">
                        <h4>Name: {a.hissadarName}</h4>
                        <h6>
                          Hissa Number:{" "}
                          <span className="hissaNumber">
                            {a.sequencenNumber}/{i + 1}
                          </span>
                        </h6>
                        <p className="phoneNumber">
                          Phone Number: {a.contact1 ? a.contact1 : a.contact2}
                        </p>
                        <p className="phoneNumber">Payment: {a.isPaid}</p>
                      </div>
                    </div>
                    <div className="hissaBtn">
                      <button
                        className="editBtn"
                        onClick={() => {
                          setEditObj({ ...a, hissaNumber: i + 1 });
                          showModal();
                        }}
                      >
                        Edit
                      </button>
                      <Popconfirm
                        title="Are you sure to delete this hissa"
                        onConfirm={(e) => {
                          confirm(e);
                          deleteHissaFn(a._id);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button className="deleteBtn">Delete</button>
                      </Popconfirm>
                    </div>
                  </div>
                  //for contribution
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ store }) => ({
  allHissaData: store.allHissaData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      allHissa,
      deleteHissa,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Hissa);
